import 'react-quill/dist/quill.snow.css';


import React, { useState } from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';


import Card from '@material-ui/core/Card';
import ReactQuill, { Quill } from 'react-quill';
import { Wrapper } from '../components';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import jsPDF from 'jspdf'
import PDFViewer from 'pdf-viewer-reactjs'
import { RenderPageProps, Viewer } from '@react-pdf-viewer/core';
import TextField from "@material-ui/core/TextField";

import ReactLoading from 'react-loading';

import Barcode from 'react-barcode';

// import img from "../images/avatar.jpg";

import bwipjs from 'bwip-js';

(function(API){
  API.myText = function(txt, options, x, y,z) {
      options = options ||{};
      /* Use the options align property to specify desired text alignment
       * Param x will be ignored if desired text alignment is 'center'.
       * Usage of options can easily extend the function to apply different text 
       * styles and sizes 
      */
     var x=0
      if( options.align == "center" ){
          // Get current font size
          var fontSize = this.internal.getFontSize();

          // Get page width
          var pageWidth = this.internal.pageSize.getWidth();
          console.log(pageWidth)

          // Get the actual text's width
          /* You multiply the unit width of your string by your font size and divide
           * by the internal scale factor. The division is necessary
           * for the case where you use units other than 'pt' in the constructor
           * of jsPDF.
          */
          var txtWidth = this.getStringUnitWidth(txt)*fontSize/this.internal.scaleFactor;
          console.log(txtWidth)
          // Calculate text's x coordinate
          x = ( pageWidth - txtWidth ) / 2;
      }
      // for GroupTitleNew 13-dec-2022
      if( options.align == "start" ){
        // Get current font size
        var fontSize = this.internal.getFontSize();

        // Get page width
        var pageWidth = this.internal.pageSize.getWidth();
        console.log("pagewidth "+pageWidth)

        // Get the actual text's width
        /* You multiply the unit width of your string by your font size and divide
         * by the internal scale factor. The division is necessary
         * for the case where you use units other than 'pt' in the constructor
         * of jsPDF.
        */
        var txtWidth = this.getStringUnitWidth(txt)*fontSize/this.internal.scaleFactor;
        console.log("txtwidth"+txtWidth)
        // Calculate text's x coordinate
        x = ( pageWidth - txtWidth ) / 12;
        console.log("value of x"+x)
    }
      // phly ye thi underline k lye ab change ki hai ye niche call ho rha hai TestTile 12-dec
      // this.line(x,152,x+txtWidth,152)
      //yhan pe fix value thi es ko z kr dya hai value dynamic pass ki hain
      this.line(x,z,x+txtWidth,z)

      // Draw text at x,y
      console.log(x)
      console.log(y)
      console.log(txt)
      this.text(txt,x,y);
  }
})(jsPDF.API);

export default class RadiologyPDF extends React.Component {
  constructor (props) {
    super(props)
    console.log("check props")
    console.log(props)
    this.state = { 
      editorHtml: '', theme: 'snow', pdf:"",   
      pdfHtml:"",
      PurePDF:"",
      DoctorName:"",
      DoctorInfo:"",
      loading:true,
      groupTitle:"",
      groupImage:"",
  margins :{
    top: 160,
      bottom: 120,
      left: 70,
      width: 450
     } }
     
    this.handleChange = this.handleChange.bind(this)
    this.generate = this.generate.bind(this)
    this.svgString2Image=this.svgString2Image.bind(this)
    this.base64toBlob=this.base64toBlob.bind(this)
    this.generatePdf=this.generatePdf.bind(this)

  }
  componentDidMount(){
    console.log("Hight for Xry result and UltraSound",this.props.xRayHeight)
    console.log("Hight for Xry result and UltraSound",this.props.imageSend)

    var obj={
        Invoice_Aux_ID:this.props.Invoice_Aux_ID+"",
        User_ID:localStorage.getItem("user_id"),
    }
    var url ="https://reports.mpl-labs.pk:8443/4DACTION/RadiologyTestsResultsViewModify"
           
    fetch(url,{
      method: 'POST',
      'Content-Type':"application/json",
      body:JSON.stringify(obj)
    }).then((res)=>res.json()).then((res)=>{
    
       document.getElementById('preview').innerHTML=res[0].HTML_Report
       this.setState({editorHtml:res[0].HTML_Report})
       this.setState({loading:false})
       this.setState({DoctorName:res[0].User_DoctorName})
       this.setState({DoctorInfo:res[0].DoctorDegree})
      //  22-dec-2022 for groupTitle and image
       this.setState({groupTitle:res[0].group_title})
       this.setState({groupImage:res[0].image_base64})
    })
      
  }
 base64toBlob = (string) => {
   
    const bytes = atob(string);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
};
  svgString2Image=(svgString, width, height, format, callback)=>{
    
    var temp=document.getElementById('barcode').innerHTML
    // set default for format parameter
    format = format ? format : 'jpg';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp)));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    var context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () { // async (happens later)
        // clear canvas
        context.clearRect(0, 0, width, height);
        // draw image with SVG data to canvas
        context.drawImage(image, 0, 0, width, height);
        // snapshot canvas as png
        var pngData = canvas.toDataURL('image/jpeg');
        // pass png data URL to callbac
        callback(pngData)
    }; // end async
    image.src=svgData
  
  }
  handleChange (html) {

    var manipulated_html=html.replaceAll('class="ql-size-small"','style="font-size:10px"')
    manipulated_html=manipulated_html.replaceAll('<p','<p style="font-size:16px" ')
    manipulated_html=manipulated_html.replaceAll('<li','<li style="font-size:16px" ')
    manipulated_html=manipulated_html.replaceAll('<ol','<ol style="font-size:16px" ')
    manipulated_html=manipulated_html.replaceAll( '<br>','<p><span style="color: rgb(255, 255, 255);">Space</span></p>')
  
    document.getElementById("preview").innerHTML=manipulated_html
    console.log(manipulated_html)
  this.setState({ editorHtml: html });
  }
  
  handleThemeChange (newTheme) {
    if (newTheme === "core") newTheme = null;
    this.setState({ theme: newTheme })
  }



generate(name,refBy,invoice_id,age_gender,Salutation,TestTitle, InvoiceDate, InvoiceTime,UserName, DoctorName,DoctorInfo,GroupTitleNew,imageCond,Ref_No,ReportDate,ReportTime,)
{
  
 // image from props that are in base64 and dispaly in pdf 13-dec-22 && 22-dec Imagecond (image condition apply when image!="" then it will show )
  // var tesTmg= "data:image/png;base64,"+`${this.props.imageSend}`;
 var tesTmg= "data:image/png;base64,"+`${this.state.groupImage}`;

  console.log("###############",tesTmg)

    this.svgString2Image(this.props.barcode,800,600,'jpg',(barcode)=>{
      

        var Uname=localStorage.getItem('name')
        const user_name = Uname.charAt(0).toUpperCase() + Uname.slice(1)
  
      var pdf = new jsPDF('p', 'pt', 'a4');
      pdf.setFontSize(10);
      pdf.setFont('arial','normal')
      var elementHandler = {
        '#ignorePDF': function (element, renderer) {
          return true;
        }
      };
      
      // { TestTitle.includes("X-RAY")&& <>{console.log("hai")}</>}
      pdf.fromHTML(
        
        document.getElementById('preview'), 
        70, // x coord
        // 145, // ye 155 tha befor 12-dec
        // this is for X-ray Result height ultraSound k lye 155 thk hai but X-ray k 145 es lye Lab_Test se he ye value send ki hai this.props.xRayHeight
        this.props.xRayHeight,
             {
                 // y coord
                 width: this.state.margins.width// max width of content on PDF,
                 ,'elementHandlers': elementHandler
  
             },function(dispose) {
        var totalpages=pdf.internal.getNumberOfPages()
           for(var i = totalpages; i >= 1; i--)
           {
             pdf.setPage(i);                            
               //header
               pdf.setFontStyle('normal');
               //header Image
               pdf.addImage(require(`./header30-07-24.jpeg`), 'jpg', 30, 15, 190,90); 
              //  pdf.addImage(require(`./headerr.png`), 'PNG', 35, 20, 170,80);
                // ye old tha headerOld

               //changes for image ye phly wali hn 
              //  pdf.setFillColor(242, 240, 240);
              //  pdf.rect(268, 50, 70, 18, "F");
              //  pdf.rect(268, 50, 70, 18, "F");
              //  pdf.line(268,68,338,68) //Bottom
              //  pdf.line(268,50,338,50) // Top
              //  pdf.line(268,50,268,68) //Left
              //  pdf.line(338,50,338,68) //Right
              //for images es pe condition lgani hai agr image hai to ye show  ho otherwise oper wala he show ho 
              if(imageCond !=""){   
                pdf.addImage(tesTmg, 'PNG', 250, 63, 100,63); 
                
              //  pdf.addImage(require(`./4301845.png`), 'PNG', 250, 63, 100,63); 

               pdf.setFillColor(242, 240, 240);
               pdf.rect(250, 35, 100, 20, "F");
               pdf.rect(250, 35, 100, 20, "F");
               pdf.line(250,55,350,55) //Bottom
               pdf.line(250,35,350,35) // Top
               pdf.line(250,35,250,55) //Left
               pdf.line(350,35,350,55) //Right
              }
              else{
               pdf.setFillColor(242, 240, 240);
               pdf.rect(268, 50, 70, 18, "F");
               pdf.rect(268, 50, 70, 18, "F");
               pdf.line(268,68,338,68) //Bottom
               pdf.line(268,50,338,50) // Top
               pdf.line(268,50,268,68) //Left
               pdf.line(338,50,338,68) //Right
              }
               pdf.setFontStyle('bold');
                pdf.setFontSize(16);
               //changes for image
               //ye function call hua hai oper jis k ye parameter hn es k sth he underline b lag rhi hai line 25 pe // this.line(x,152,x+txtWidth,152) ye b change ki hai 157
                // pdf.myText(TestTitle,{align: "center"},70,150); 
             
               
              //for X-ray es pe condition lgani hai agr Xray hai to ye show  ho otherwise oper wala he show ho 
            // 3rd value mny di hai take testTile ki line ko dynamic bnaya ja sky 157 as z use ho rha hai
            //yhan image aae to niche ho otherwise na ho GrouTitleNew ki jga image pe cond lgi gi
              // if(GroupTitleNew !=""){  
            if(imageCond !="" || GroupTitleNew !=""){  
                 pdf.myText(TestTitle,{align: "center"},70,155,157); 
              }else{
                pdf.myText(TestTitle,{align: "center"},70,140,142); 
              }
              
            
              //changes for image
              //  pdf.setFontStyle('bold');
              //  pdf.setFontSize(7.5);
              //  pdf.text("MPL ID : "+invoice_id, 275, 62 );
              //for images es pe condition lgani hai agr image hai to ye show  ho otherwise oper wala he show ho 
              // if(GroupTitleNew !=""){  
            if(imageCond !=""){  
              pdf.setFontStyle('bold');
              pdf.setFontSize(9.0);
               pdf.text("MPL ID : "+invoice_id, 265, 48);
              }else{
                   pdf.setFontStyle('bold');
               pdf.setFontSize(7.5);
               pdf.text("MPL ID : "+invoice_id, 275, 62 );
              }
               
               pdf.setFontSize(8.5);
               // Barcode Image
             pdf.addImage(barcode, 'JPG', 400, 10, 170,40); 
               pdf.text(Salutation+" "+name, 410, 60 );
               pdf.setLineWidth(0.1);
                    //ye add kiye hn radiology me thy but yhan ni 2 jan 2023 sir najam 7501303 niche wly sary 344 tak
            //  pdf.line(410, 70, 560,70); // Name Line
            //  pdf.line(410, 90, 560,90); // Age Line
            //  pdf.line(410, 110, 560,110); // Ref By Line
            pdf.line(410, 65, 560,65); // Name Line
            pdf.line(410, 80, 560,80); // Age Line
            pdf.line(410, 95, 560,95); // Ref By Line
            pdf.line(410, 110, 560,110); // Ref no Line
            // if(Ref_No!=""){
            //   pdf.line(410, 125, 560,125); // Ref No By Line
            //  }
             pdf.line(410, 125, 560,125); // Result By Line
           
            
             pdf.setFontSize(7.5);
             pdf.setFontStyle('bold');
             pdf.text("Age/Gender", 410, 75);
              pdf.setFontStyle('normal');
             pdf.text(":   "+age_gender, 470, 75);
            //  pdf.text("Age/Gender", 410, 82.5);
            //  pdf.text(":  "+age_gender, 465, 82.5);
             pdf.setFontStyle('bold');
             pdf.text("Ref By", 410, 90);
             pdf.setFontStyle('normal');
             pdf.text(":   "+refBy, 470, 90);
            
            
             console.log("Reference No")
             console.log(Ref_No)
             pdf.setFontStyle('bold');
              pdf.text("Reference No", 410, 105);
             pdf.setFontStyle('normal');
             if(Ref_No!=""){
              pdf.text(":   "+Ref_No, 470, 105);
             }else{
              pdf.text(":   Nil", 470, 105);
             }
             pdf.setFontStyle('bold');
               pdf.text("Result Date/Time", 410, 120);
             pdf.setFontStyle('normal');
             pdf.text(":   "+ReportDate+"  "+ReportTime, 470, 120);


            //  pdf.text("Ref By", 410, 102.3);
            //  pdf.text(":  "+refBy, 465, 102.5);
            
               if(GroupTitleNew !=""){
                pdf.setFontSize(9.2);
                pdf.setFontStyle('bold');
                //function call lkiya hai us me align start new dala hai es k lye 13-dec
                pdf.myText(GroupTitleNew,{align: "start"},35,134,136); 

              //  pdf.text(GroupTitleNew, 35, 134,);
              //   pdf.setLineWidth(0.5);
              // pdf.line(35,136,217,136) //Right)
               }
               
                    // Signing Start
              
                    pdf.setFontSize(7);
                    pdf.text("User ID : "+user_name+" Inv. Date : "+InvoiceDate+" Inv. Time : "+InvoiceTime,pdf.internal.pageSize.getWidth()-70 , pdf.internal.pageSize.getHeight()-120,"right");
                    pdf.setFontStyle('bold');
                    pdf.setFontSize(9);
       
                    pdf.setTextColor(255,0,0)
                    pdf.text("This is a digitally verified Report",pdf.internal.pageSize.getWidth()-70 , pdf.internal.pageSize.getHeight()-110,"right");
                    pdf.setTextColor(0,0,0)
                    pdf.text(DoctorName,pdf.internal.pageSize.getWidth()-70 , pdf.internal.pageSize.getHeight()-100,"right");
                    pdf.setFontStyle('normal');
                    var margin=90
                    var degree=DoctorInfo.split('|')
                    degree.map((item)=>{
                      pdf.text(item,pdf.internal.pageSize.getWidth()-70 , pdf.internal.pageSize.getHeight()-margin,"right");
                      margin=margin-10
                    })
                    // Signing End
                    if(totalpages>1){
                      pdf.text("Page "+i+" of "+totalpages,70 , pdf.internal.pageSize.getHeight()-110,"left");
                     
                     }  
               
               pdf.setLineCap(2);
               pdf.setLineCap(2);
               //footer
               // Footer change 3-fev-2023
               // if(imageCond !=""){ this cond apply on 7-feb group image aa rhi hai to new footer show ni ho rha tha pdf open ni ho rhi thi reson ye thi footer ka size and dimension Footer.jpg se match ni kr rhi thi es lye us ka size reduce kiya ab show ho rhi hai
// footer updated on 31-aug-2023
               //pdf.addImage(require(`./FooterRadSec.jpg`), 'JPG', 0, pdf.internal.pageSize.getHeight()-50 , pdf.internal.pageSize.getWidth(),50); 
              //  pdf.addImage(require(`./FooterRadiology.jpg`), 'JPG', 0, pdf.internal.pageSize.getHeight()-50 , pdf.internal.pageSize.getWidth(),50);     
            // footer change27-july-2024 phly FooterRadiologyNew1 tha
              if(imageCond !=""){ 
              //  pdf.addImage(require(`./FooterRadiologyjuly24.jpeg`), 'JPG', 0, pdf.internal.pageSize.getHeight()-50 , pdf.internal.pageSize.getWidth(),50); 
              pdf.addImage(require(`./FooterRadiologyjuly30.jpeg`), 'JPG', 0, pdf.internal.pageSize.getHeight()-70 , pdf.internal.pageSize.getWidth(),70); 
             
            }else{
                // 12-aug-2024 change footerRdaiology30-07-2024 
                // pdf.addImage(require(`./FooterRadiologyjuly24.jpeg`), 'JPG', 0, pdf.internal.pageSize.getHeight()-70 , pdf.internal.pageSize.getWidth(),70); 
                
                pdf.addImage(require(`./FooterRadiologyjuly30.jpeg`), 'JPG', 0, pdf.internal.pageSize.getHeight()-70 , pdf.internal.pageSize.getWidth(),70); 

               }
               pdf.page++;
           }
          }, 
          this.state.margins
     );
             this.setState({PurePDF:pdf.output('datauristring')})
            const blob = this.base64toBlob(pdf.output('datauristring').split('base64,')[1]);
            const url = URL.createObjectURL(blob);
            this.setState({pdfHtml:url})
    })
   
};
  // 18-oct-2024 auto send whatsapp report
   generatePdf = async () => {
    // const doc = <Report invoice={reportData} pic={chartImage} countdown={countdown}  />;
    // const blob = await pdf(doc).toBlob();
   
    // const reader = new FileReader();
    // reader.readAsDataURL(blob);
  
    // console.log(rows[0].mobile.replace("0","+92"))
    // console.log(rows[0])
console.log(this.props.UserNumber)
if(this.props.UserName){
    setTimeout(async () => {
      const response = await fetch(
        'https://api.ultramsg.com/instance95224/messages/document',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            token: '2vxh4vp43ft76vvm',
            to: this.props.UserNumber.replace("0","+92"),
            // to: "+923165153293",

            filename: this.props.invoice_id+'.pdf',
            document: this.state.PurePDF, // Base64-encoded PDF
            caption: 'Please find enclosed your Reports.Thank you for considering MPL as your healthcare partner.',
          })
        }
      );
      console.log(response)
      if (response.ok) {
        console.log('Document sent successfully!');
        alert("Reports sent successfully !")
      } else {
        console.error('Failed to send document:',  response.text());
        alert('Failed to send Reports ')
      }
    }, 500);
}
else{
  alert("User Number Not Found 1")
}
    
  };

  render () {
    return (
      <Wrapper>
        {/* 18-oct-2024 */}
        {
              this.state.pdfHtml!="" ?
        <Button
          variant="contained"
          style={{backgroundColor:"green",alignSelf:"flex-end",marginLeft:"80%"}}
          color="primary"
          size="large"
          startIcon={<WhatsAppIcon fontSize={"large"} />}
          onClick={() => {
            // if(toPrint=={}){
            //   alert("Please Select Tests!!")
            //   return
            // }
            this.generatePdf()
          }}

        >Send Whatsapp </Button>
        :<></>}
          <div id="preview" hidden>

</div>

          {this.state.loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
      <Card className="overflow-visible">
     
        <div style={{
          textAlign:'center',
        
        }}>  <Button variant="contained" color="primary" onClick={()=>{
            this.setState({pdfHtml:""})
            this.setState({PurePDF:""}) 
            this.generate(this.props.name,this.props.refBy,this.props.invoice_id,this.props.age_gender, this.props.Salutation,this.props.TestTitle, this.props.InvoiceDate,this.props.InvoiceTime,this.props.UserName,this.props.DoctorName,this.props.DoctorInfo,this.state.groupTitle,this.state.groupImage,this.props.Ref_No, this.props.ReportDate,this.props.ReportTime,)
        }}>Generate PDF Preview</Button>
     
        
        </div>
         
        <Grid container style={{
            height:"800px"
        }}>

         <div id="barcode" hidden>
        
         <Barcode value={this.props.invoice_id} displayValue={false} />
        </div>
       
       
        
         <Grid item lg={12} md={12}  style={{
              border:"1px solid black",
              background:"black",
              height:"800px"
          }}>
       {
              this.state.pdfHtml!="" ?
              <iframe src={this.state.PurePDF}  
              
              style={{
                  width:"100%",
                  height:"100%"
              }}
              allowfullscreen></iframe>
            
          
          :<div
          style={{
              alignItems: 'center',
              color:"white",
              border: '2px dashed rgba(255,255,255)',
              display: 'flex',
              fontSize: '2rem',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
          }}
      >
          Preview area
      </div>
  
          }
           </Grid>
        
      </Grid>
     
        </Card>
  }
    </Wrapper>
     )
  }
}

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
RadiologyPDF.modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': ['10px','12px'] }],
    [{ 'align': [] }],
  
    ['clean'] 
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}
